import request from "../../../api/request";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  PP_SETTINGS: `${host}v2/admin/app_settings`,
};

const orientationApi = {
  GET: (query) => request("GET", URL.PP_SETTINGS, query),

  UPDATE: (data) => request("PUT", URL.PP_SETTINGS, data),
};

export default orientationApi;
