import { useEffect, useState } from "react";
import styles from "../../../styles/_charts.module.scss";
import { useTranslation } from "react-i18next";
import ChartComponent from "../../../../../_components/chart/ChartComponent";
import { Area } from "@ant-design/plots";
import REQUESTS from "../../../../../api/requests";
import dayjs from "dayjs";

const ImpressionChart = () => {
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState("");

  const [impressionData, setImpressionData] = useState({
    data: [],
    yField: "value",
    xField: "date",
    seriesField: "type",
  });

  const handleDateRange = (date) => {
    if (!date) {
      setDateRange({
        start_date: dayjs(new Date()).format("YYYY-MM-DD"),
        end_date: dayjs(new Date()).format("YYYY-MM-DD"),
      });
    } else {
      setDateRange({
        start_date: dayjs(date[0]).format("YYYY-MM-DD"),
        end_date: dayjs(date[1]).format("YYYY-MM-DD"),
      });
    }
  };

  useEffect(() => {
    const fetchImpressions = async () => {
      try {
        const response = await REQUESTS.DASHBOARD.ADS.IMPRESSION(dateRange);
        if (response && Array.isArray(response)) {
          const formattedData = response.map((item) => ({
            ...item,
            date: dayjs(item.date).format("YYYY-MM-DD"),
          }));

          setImpressionData((prevData) => ({
            ...prevData,
            data: formattedData,
          }));
        }
      } catch (err) {
        console.error("Error fetching impressions:", err);
      }
    };

    fetchImpressions();
  }, [dateRange]);

  return (
    <div className={styles["chart-wrapper"]}>
      <ChartComponent title={t("impression")} dateRange={handleDateRange}>
        <Area {...impressionData} />
      </ChartComponent>
    </div>
  );
};

export default ImpressionChart;
