export const filterOPtions = {
  adsTypeFilterOptions: (t) => [
    { text: t("splash_screen"), value: "splash screen" },
    { text: t("collection"), value: "collection" },
    { text: t("volume_ads"), value: "volume" },
    { text: t("banner_ads"), value: "player banner" },
  ],

  roleOPtions: (t) => [
    { text: t("enabled"), value: true },
    { text: t("disabled"), value: false },
  ],
  archiveFilterOPtions: (t) => [
    { text: t("archived"), value: false },
    { text: t("not_archived"), value: true },
  ],
};
