import Icon from "@ant-design/icons";
import ICONS from "../../config/icons";

import PATHS from "../../config/paths";
import MessagesMenu from "./MessagesMenu";

export default function checkPermissions(permissions, t) {
  let list = [
    {
      label: t("dashboard"),
      key: PATHS.DASHBOARD_CONTENT,
      icon: <Icon component={() => ICONS.DASHBOARD} />,
    },
  ];

  const entertainments = true;

  if (permissions?.Vod_Manager) {
    list.push({
      label: t("videos"),
      key: "vod-manager",
      icon: <Icon component={() => ICONS.VOD} />,
      children: [
        {
          key: PATHS.MEDIAS,
          label: t("videos"),
        },
        {
          key: PATHS.SERIES,
          label: t("series"),
        },
        {
          key: PATHS.COLLECTION,
          label: t("collections"),
        },
        {
          key: PATHS.GENRES,
          label: t("genres"),
        },
        {
          key: PATHS.CAST,
          label: t("cast"),
        },
        {
          key: PATHS.MEDIA_TRANSCODING,
          label: t("uploads"),
        },
        {
          key: PATHS.CONTENT_PROVIDER,
          label: t("content_provider"),
        },
      ],
    });
  }

  if (permissions?.LIVE_TV) {
    list.push({
      label: t("live_tv"),
      key: "live-tv",
      icon: <Icon component={() => ICONS.LIVE_TV} />,

      children: [
        {
          key: PATHS.LIVE_TV,
          label: t("live_tv"),
        },
        {
          key: PATHS.LIVE_TV_CATEGORIES,
          label: t("categories"),
        },
        permissions?.EPG && {
          label: t("epg"),
          key: PATHS.EPG,
          // icon: <Icon component={() => ICONS.EPG} />,
        },

        permissions?.Archive && {
          label: t("archiver"),
          key: "catch-up",
          // icon: <Icon component={() => ICONS.ARCHIVE} />,
          children: [
            {
              key: PATHS.CATCH_UP_SERVERS,
              label: t("servers"),
            },

            {
              key: PATHS.CATCH_UP_RECORDINGS,
              label: t("recordings"),
            },
          ],
        },

        permissions?.Streaming && {
          label: t("streamer"),
          key: "streamer",
          // icon: <Icon component={() => ICONS.STREAM} />,
          children: [
            {
              key: PATHS.STREAM_SERVERS,
              label: t("servers"),
            },

            {
              key: PATHS.STREAMS,
              label: t("streams"),
            },
          ],
        },

        permissions?.Transcoding && {
          label: t("transcoders"),
          key: "transcoders",
          // icon: <Icon component={() => ICONS.TRANSCODING} />,
          children: [
            {
              key: PATHS.TRANSCODERS_GROUPS,
              label: t("groups"),
            },

            {
              key: PATHS.TRANSCODERS_SERVERS,
              label: t("servers"),
            },

            {
              key: PATHS.TRANSCODING,
              label: t("transcoding"),
            },

            {
              key: PATHS.TRANSCODERS_RESOLUTIONS,
              label: t("resolutions"),
            },
          ],
        },
      ],
    });
  }

  // if (permissions?.entertainments) {
  if (entertainments) {
    list.push({
      label: t("entertainments"),
      key: "entertainments",
      icon: <Icon component={() => ICONS.ENTERTAINMENT} />,

      children: [
        {
          key: PATHS.MUSIC,
          label: t("music"),
          children: [
            {
              key: PATHS.MUSIC,
              label: t("music"),
            },
            {
              key: PATHS.MUSIC_CATEGORIES,
              label: t("categories"),
            },
          ],
        },
        {
          key: PATHS.RADIO,
          label: t("radio"),
          children: [
            {
              key: PATHS.RADIO,
              label: t("radio"),
            },
            {
              key: PATHS.RADIO_CATEGORIES,
              label: t("categories"),
            },
          ],
        },
        {
          key: PATHS.GAMES,
          label: t("games"),
          children: [
            {
              key: PATHS.GAMES,
              label: t("games"),
            },
            {
              key: PATHS.GAMES_CATEGORIES,
              label: t("categories"),
            },
          ],
        },
      ],
    });
  }

  if (permissions?.chat_message) {
    list.push({
      label: <MessagesMenu />,
      key: PATHS.MESSAGES,
      icon: <Icon component={() => ICONS.CHAT} />,
    });
  }

  if (permissions?.lessons || permissions?.quiz) {
    list.push({
      label: t("lessons"),
      key: "lessons",
      icon: <Icon component={() => ICONS.LESSONS} />,
      children: [
        {
          key: PATHS.QUIZ,
          label: t("quiz"),
        },
        {
          key: PATHS.LESSONS,
          label: t("lessons"),
        },
      ],
    });
  }

  list.push(
    {
      label: t("users"),
      key: "users",
      icon: <Icon component={() => ICONS.USERS} />,

      children: [
        {
          key: PATHS.USERS_GROUP,
          label: t("groups"),
        },
        {
          key: PATHS.USERS,
          label: t("users"),
        },
      ],
    },

    permissions?.reseller && {
      label: t("resellers"),
      key: "resellers",
      icon: <Icon component={() => ICONS.RESELLERS} />,

      children: [
        {
          key: PATHS.RESELLERS,
          label: t("resellers"),
        },
      ],
    },

    {
      label: t("monetization"),
      key: "payments",
      icon: <Icon component={() => ICONS.PAYMENTS} />,

      children: [
        permissions?.Ads && {
          label: `${t("advertisements")}`,
          key: PATHS.ADS,
        },
        {
          key: PATHS.PAYMENT_SUBSCRIPTION,
          // label: "Subscriptions (SVOD)",
          label: `${t("subscription")} (SVOD)`,
        },
        permissions?.Vod_Manager && {
          key: PATHS.TVOD_PAYMENT,
          label: "PPTV (TVOD)",
        },

        {
          key: PATHS.PAYMENT_CONFIGS,
          label: t("configs"),
        },

        {
          key: PATHS.PAYMENT_HISTORY,
          label: t("history"),
        },
      ],
    }

    // {
    //   label: "Email notification",
    //   key: PATHS.EMAIL_NOTIFICATION,
    //   icon: <Icon component={() => ICONS.NOTIFICATIONS} />,
    // },

    // {
    //   label: "Admin",
    //   key: PATHS.ADMIN,
    //   icon: <Icon component={() => ICONS.ADMIN} />,
    // }
  );

  if (permissions?.Ads) {
    list.push({
      label: t("custom_ads"),
      key: "custom_ads",
      icon: <Icon component={() => ICONS.CUSTOM_ADS} />,
      children: [
        {
          key: PATHS.CUSTOM_ADS_CAMPAIGNS,
          label: t("campaign"),
        },
        {
          key: PATHS.CUSTOM_ADS_CLIENT,
          label: t("clients"),
        },
        {
          key: PATHS.CUSTOM_ADS,
          label: t("ads"),
        },

        {
          key: PATHS.CUSTOM_ADS_FILTER,
          label: t("filters"),
        },
        {
          key: PATHS.CUSTOM_ADS_PRICING,
          label: t("pricing"),
        },
      ],
    });
  }

  if (permissions?.launcher) {
    list.push({
      label: t("launcher"),
      key: "launcher",
      icon: <Icon component={() => ICONS.LAUNCHER} />,

      children: [
        {
          key: PATHS.LAUNCHER_APPS,
          label: t("apps"),
        },

        {
          key: PATHS.LAUNCHER_DEVICES,
          label: t("devices"),
        },

        {
          key: PATHS.LAUNCHER_MESSAGES,
          label: t("messages"),
        },

        {
          key: PATHS.LAUNCHER_CONFIGS,
          label: t("configs"),
        },
      ],
    });
  }

  if (permissions?.web_page) {
    list.push({
      label: t("web_page"),
      key: "web-page",
      icon: <Icon component={() => ICONS.WEB} />,

      children: [
        {
          key: PATHS.WEB_SETTINGS,
          label: t("settings"),
        },

        {
          key: PATHS.WEB_NEWS,
          label: t("news"),
        },

        {
          key: PATHS.WEB_FAQ,
          label: t("faq"),
        },

        {
          key: PATHS.WEB_FEATURE,
          label: t("feature"),
        },

        {
          key: PATHS.WEB_SLIDER,
          label: t("slider"),
        },

        {
          key: PATHS.WEB_PLATFORMS,
          label: t("platforms"),
        },

        {
          key: PATHS.WEB_AVAILABLE_DEVICES,
          label: t("available_devices"),
        },
      ],
    });
  }

  list.push({
    label: t("application"),
    key: "application",
    icon: <Icon component={() => ICONS.APPLICATION_SETTINGS} />,

    children: [
      {
        key: PATHS.APPLICATION_BASIC,
        label: t("basic_info"),
      },
      {
        key: PATHS.APPLICATION_BRANDING,
        label: t("branding"),
      },
      {
        key: PATHS.APPLICATION_LANGUAGES,
        label: t("languages"),
      },
      {
        key: PATHS.APPLICATION_USER_ICONS,
        label: t("user_icons"),
      },
      {
        key: PATHS.MENU_CONFIG,
        label: t("menu_config"),
      },
      {
        key: PATHS.APPLICATION_RADIUSES,
        label: t("application_radius"),
      },
      {
        key: PATHS.POSTER_ORIENTATIONS,
        label: t("poster_orientations"),
      },
    ],
  });

  // if (permissions?.EPG) {
  //   list.push({
  //     label: "EPG",
  //     key: PATHS.EPG,
  //     icon: <Icon component={() => ICONS.EPG} />,
  //   });
  // }

  // if (permissions?.Transcoding) {
  //   list.push({
  //     label: "Transcoders",
  //     key: "transcoders",
  //     icon: <Icon component={() => ICONS.TRANSCODING} />,

  //     children: [
  //       {
  //         key: PATHS.TRANSCODERS_GROUPS,
  //         label: "Groups",
  //       },

  //       {
  //         key: PATHS.TRANSCODERS_SERVERS,
  //         label: "Servers",
  //       },

  //       {
  //         key: PATHS.TRANSCODING,
  //         label: "Transcoding",
  //       },

  //       {
  //         key: PATHS.TRANSCODERS_RESOLUTIONS,
  //         label: "Resolutions",
  //       },
  //     ],
  //   });
  // }

  // if (permissions?.Streaming) {
  //   list.push({
  //     label: "Streamer",
  //     key: "streamer",
  //     icon: <Icon component={() => ICONS.STREAM} />,

  //     children: [
  //       {
  //         key: PATHS.STREAM_SERVERS,
  //         label: "Servers",
  //       },

  //       {
  //         key: PATHS.STREAMS,
  //         label: "Streams",
  //       },
  //     ],
  //   });
  // }

  // if (permissions?.Archive) {
  //   list.push({
  //     label: "Catch-up",
  //     key: "catch-up",
  //     icon: <Icon component={() => ICONS.ARCHIVE} />,

  //     children: [
  //       {
  //         key: PATHS.CATCH_UP_SERVERS,
  //         label: "Servers",
  //       },

  //       {
  //         key: PATHS.CATCH_UP_RECORDINGS,
  //         label: "Recordings",
  //       },
  //     ],
  //   });
  // }

  // list.push({
  //   label: "History",
  //   key: PATHS.HISTORY,
  //   icon: <Icon component={() => ICONS.HISTORY} />,
  // });

  if (permissions?.Import) {
    list.push({
      label: t("import"),
      key: PATHS.IMPORT,
      icon: <Icon component={() => ICONS.IMPORT} />,
    });
  }

  list.push({
    label: t("settings"),
    key: "settings",
    icon: <Icon component={() => ICONS.SETTINGS} />,
    children: [
      {
        label: t("admin"),
        key: PATHS.ADMIN,
        // icon: <Icon component={() => ICONS.ADMIN} />,
      },
      {
        key: PATHS.SETTINGS,
        label: t("general_settings"),
      },

      // {
      //   key: PATHS.WEB_HOOKS,
      //   label: "Web Hooks",
      // },

      {
        key: PATHS.CDN,
        label: "CDN",
      },

      // {
      //   key: PATHS.DOCUMENTS,
      //   label: "Documents",
      // },

      {
        key: PATHS.STORE_LINKS,
        label: t("stor_link"),
      },
      {
        key: PATHS.SMTP_CONFIG,
        label: t("smtp_configs"),
      },
      {
        key: PATHS.SOCIAL_MEDIA_AUTH,
        label: t("social_auth"),
      },

      // {
      //     key: PATHS.VERSIONS,
      //     label: "Versions",
      // },

      permissions?.update_with_apk && {
        key: PATHS.APKS,
        label: t("apk_s"),
      },

      {
        key: PATHS.SERVICE_NOTIFICATIONS,
        label: t("service_notification"),
      },
      {
        label: t("last_active"),
        key: PATHS.HISTORY,
        // icon: <Icon component={() => ICONS.HISTORY} />,
      },
      // {
      //   label: t("feed"),
      //   key: PATHS.FEED,
      // },
      // permissions?.hy
    ],
  });

  if (permissions?.promo_code) {
    list.push({
      label: t("promo_codes"),
      key: PATHS.PROMO_CODES,
      icon: <Icon component={() => ICONS.PROMO} />,
    });
  }

  return list;
}
