import { useEffect, useState } from "react";
import { Button, Drawer, Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const GameCategoriesDrawer = ({ open, onClose, onSave, host, editData }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const finish = (values) => {
    const body = {
      name: values.name,
    };

    onSave(body);
  };

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        name: editData.name,
      });
    }

    return () => {
      form.resetFields();
    };
  }, [editData, open]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width={500}
      title={editData ? t("edit_category") : t("add_category")}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            {t("cancel")}
          </Button>
          <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
            {t("save")}
          </Button>
        </div>
      }
    >
      <Form form={form} onFinish={finish} layout="vertical">
        <Form.Item
          label={t("name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("name_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default GameCategoriesDrawer;
