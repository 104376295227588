import request from "../../api/request";
import { appHost } from "../../helpers/appHost";

const host = appHost;

const URL = {
  MUSIC_CATEGORIES: `${host}entertainment/admin/music_categories`,
  MUSIC: `${host}entertainment/admin/music`,

  FILE: `${host}entertainment/file`,

  RADIO_CATEGORIES: `${host}entertainment/admin/radio_categories`,
  RADIO: `${host}entertainment/admin/radio`,

  GAME_CATEGORIES: `${host}entertainment/admin/game_categories`,
  GAME: `${host}entertainment/admin/game`,
};

const entertainmentsApi = {
  MUSIC: {
    CATEGORIES_GETTER: (query) => request("GET", `${URL.MUSIC_CATEGORIES}/getter`, query),

    CATEGORIES: (query) => request("GET", `${URL.MUSIC_CATEGORIES}`, query),

    CATEGORIES_DELETE: (id) =>
      request("DELETE", `${URL.MUSIC_CATEGORIES}/many`, { ids: [id] }),

    ADD_CATEGORY: (data) => request("POST", URL.MUSIC_CATEGORIES, data),

    EDIT_CATEGORY: (id, data) => request("PUT", `${URL.MUSIC_CATEGORIES}/${id}`, data),

    MUSIC_GETTER: (query) => request("GET", `${URL.MUSIC}/getter`, query),

    ADD_MUSIC: (data) => request("POST", URL.MUSIC, data),

    EDIT_MUSIC: (id, data) => request("PUT", `${URL.MUSIC}/${id}`, data),

    DELETE_MUSIC: (id) => request("DELETE", `${URL.MUSIC}/many`, { ids: [id] }),
  },

  RADIO: {
    CATEGORIES_GETTER: (query) => request("GET", `${URL.RADIO_CATEGORIES}/getter`, query),
    CATEGORIES: (query) => request("GET", `${URL.RADIO_CATEGORIES}`, query),

    CATEGORIES_DELETE: (id) =>
      request("DELETE", `${URL.RADIO_CATEGORIES}/many`, { ids: [id] }),

    ADD_CATEGORY: (data) => request("POST", URL.RADIO_CATEGORIES, data),

    EDIT_CATEGORY: (id, data) => request("PUT", `${URL.RADIO_CATEGORIES}/${id}`, data),

    RADIO_GETTER: (query) => request("GET", `${URL.RADIO}/getter`, query),

    ADD_RADIO: (data) => request("POST", URL.RADIO, data),

    EDIT_RADIO: (id, data) => request("PUT", `${URL.RADIO}/${id}`, data),

    DELETE_RADIO: (id) => request("DELETE", `${URL.RADIO}/many`, { ids: [id] }),
  },

  GAME: {
    CATEGORIES_GETTER: (query) => request("GET", `${URL.GAME_CATEGORIES}/getter`, query),
    CATEGORIES: (query) => request("GET", `${URL.GAME_CATEGORIES}`, query),

    CATEGORIES_DELETE: (id) =>
      request("DELETE", `${URL.GAME_CATEGORIES}/many`, { ids: [id] }),

    ADD_CATEGORY: (data) => request("POST", URL.GAME_CATEGORIES, data),

    EDIT_CATEGORY: (id, data) => request("PUT", `${URL.GAME_CATEGORIES}/${id}`, data),

    GAME_GETTER: (query) => request("GET", `${URL.GAME}/getter`, query),

    ADD_GAME: (data) => request("POST", URL.GAME, data),

    EDIT_GAME: (id, data) => request("PUT", `${URL.GAME}/${id}`, data),

    DELETE_GAME: (id) => request("DELETE", `${URL.GAME}/many`, { ids: [id] }),
  },

  FILE: (file) => request("POST", URL.FILE, file),
};

export default entertainmentsApi;
