import apiInstance from "../api/instance";

const host = process.env.REACT_APP_RESELLER_API_HOST || "DEFAULT_REACT_RESELLER_API_HOST";

const URL = {
  RESELLER_GETTER: `${host}admin/resellers/getter`,
};

const resellerApi = {
  GETTER: (query) => apiInstance.get(URL.RESELLER_GETTER, { params: query }),

  DELETE: (id) =>
    apiInstance.delete(`${host}admin/resellers/many`, { data: { ids: id } }),

  ADD: (data) => apiInstance.post(`${host}admin/resellers`, data),

  UPDATE: (data, id) => apiInstance.put(`${host}admin/resellers/${id}`, data),
};

export default resellerApi;
