import { useState } from "react";
import { useTranslation } from "react-i18next";
import PageComponent from "../../../_components/page/PageComponent";
import CampaignsTable from "./CampaignsTable";
import REQUESTS from "../../../api/requests";
import CampaignsDrawer from "./drawer/CampaignsDrawer";
import { notification } from "antd";
import {
  useAdsClientsOPtions,
  useAdsFilterOptions,
  useAdsPricingOptions,
} from "../../../hooks/selectOptions";

const CampaignsPage = () => {
  const { t } = useTranslation();

  const clientsOptions = useAdsClientsOPtions();
  const filterOptions = useAdsFilterOptions();
  const pricingOptions = useAdsPricingOptions();

  const [showDrawer, setShowDrawer] = useState(false);
  const [getAgain, setGetAgain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(null);

  const getDataSource = (query, success, error) => {
    setLoading(true);
    try {
      REQUESTS.CUSTOM_ADS.CAMPAIGNS.GETTER({ query: JSON.stringify(query) })
        .then((response) => {
          setLoading(false);
          success(response.data);
          console.log(response.data);
        })
        .catch((err) => {
          setLoading(false);
          error(err);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onDelete = (data, type) => {
    setLoading(true);
    try {
      let id = [];

      if (type == "many") {
        id = data.join(",");
      } else {
        id = data?.toString();
      }
      REQUESTS.CUSTOM_ADS.CAMPAIGNS.DELETE(id)
        .then((response) => {
          setLoading(false);
          setGetAgain((prev) => !prev);
          notification.success({
            message: t("success"),
            description: t("deleted_successfully"),
          });
        })
        .catch((err) => {
          setLoading(false);
          notification.error({
            message: t("error"),
            description: t("delete_failed"),
          });
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = (values) => {
    setLoading(true);
    if (editable) {
      try {
        REQUESTS.CUSTOM_ADS.CAMPAIGNS.EDIT(values, editable.id)
          .then((response) => {
            setShowDrawer(false);
            setGetAgain((prev) => !prev);
            setEditable(null);
            setLoading(false);
            notification.success({
              message: t("success"),
              description: t("updated_successfully"),
            });
          })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: t("error"),
              description: t("update_failed"),
            });
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        REQUESTS.CUSTOM_ADS.CAMPAIGNS.ADD(values)
          .then((response) => {
            setLoading(false);
            setGetAgain((prev) => !prev);

            setShowDrawer(false);
            notification.success({
              message: t("success"),
              description: t("added_successfully"),
            });
          })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: t("error"),
              description: t("add_failed"),
            });

            console.error(err);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleMenuClick = ({ key }, record) => {
    if (key === "edit") {
      setShowDrawer(true);
      setEditable(record);
    } else if (key === "delete") {
      console.log("delete");
      onDelete(record.id, "one");
    }
  };

  const handleBulkAction = (key, data, response) => {
    switch (key) {
      case "delete":
        onDelete(data, "many");
        response(getAgain);
        break;
      default:
        break;
    }
  };

  return (
    <PageComponent routes={[t("custom_ads"), t("campaigns")]}>
      <CampaignsTable
        getDataSource={getDataSource}
        setShowDrawer={setShowDrawer}
        handleMenuClick={handleMenuClick}
        getAgain={getAgain}
        bulkAction={handleBulkAction}
        loading={loading}
      />

      <CampaignsDrawer
        open={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setEditable(null);
        }}
        onSave={handleSave}
        editable={editable}
        clientsOptions={clientsOptions}
        filterOptions={filterOptions}
        pricingOptions={pricingOptions}
        loading={loading}
      />
    </PageComponent>
  );
};

export default CampaignsPage;
