import { Drawer, Form, Input, Select, Switch } from "antd";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../../../../_components/button/ButtonComponent";
import icons from "../../../../config/icons";
import { useEffect } from "react";

const ResellersDrawer = ({ open, onClose, editable, countryOptions, onSave }) => {
  console.log("editable", editable);
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const body = {
      ...values,
      budget: values.budget?.toString(),
    };
    onSave(body);
  };

  useEffect(() => {
    if (editable) {
      form.setFieldsValue(editable);
    } else {
      form.resetFields();
    }
  }, [editable]);

  return (
    <Drawer
      title={editable ? t("edit_reseller") : t("add_reseller")}
      open={open}
      width={500}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <ButtonComponent
            title={t("cancel")}
            type="default"
            onClick={() => {
              onClose();
            }}
          />
          <ButtonComponent
            title={t("save")}
            icon={icons.CHECK}
            onClick={() => form.submit()}
          />
        </div>
      }
      onClose={() => {
        onClose();
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t("full_name")}
          name="full_name"
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("email")}
          name="email"
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        {!editable && (
          <Form.Item
            label={t("password")}
            name="password"
            rules={[
              {
                required: true,
                message: t("required"),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        )}
        <Form.Item
          label={t("phone")}
          name="phone"
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("address")}
          name="address"
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("country")}
          name="country"
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Select
            options={countryOptions}
            showSearch
            // filterOption={(input, option) =>
            //   (option?.label?.toLowerCase() ?? "")?.includes(input)
            // }
            // filterSort={(optionA, optionB) => {
            //   return (optionA?.label ?? "")
            //     ?.toLowerCase()
            //     ?.localeCompare((optionB?.label ?? "")?.toLowerCase());
            // }}
          />
        </Form.Item>

        <Form.Item
          label={t("budget")}
          name="budget"
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ResellersDrawer;
