import { Drawer, Form, Select, Input, Switch } from "antd";
import ButtonComponent from "../../../../_components/ButtonComponent";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const FilterDrawer = ({
  open,
  onClose,
  onSave,
  editable,
  vodOPtions,
  liveTvOptions,
  vodGenresOptions,
  liveTvCategoriesOptions,
  countriesOptions,
  loading,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const allowedOSOptions = [
    { label: "Android", value: "android" },
    { label: "iOS", value: "ios" },
    { label: "Windows", value: "windows" },
    { label: "MacOS", value: "macos" },
    { label: "Tizen (Samsung TV)", value: "tizen" },
    { label: "WebOS (LG TV)", value: "webos" },
    { label: "Roku", value: "roku" },
    { label: "FireOS (Amazon Fire TV)", value: "fireos" },
    { label: "tvOS (Apple TV)", value: "tvos" },
  ];

  const [allVodOptions, setAllVodOptions] = useState([]);
  const [allLiveTvOptions, setAllLiveTvOptions] = useState([]);

  const handleSelectChange = (selectedValues, allOptions, fieldName) => {
    if (selectedValues.includes("all")) {
      form.setFieldsValue({
        [fieldName]: allOptions.map((option) => option.value),
      });
    } else {
      form.setFieldsValue({
        [fieldName]: selectedValues,
      });
    }
  };

  const handleFinish = (values) => {
    const body = {
      name: values.name,
      allowed_for_kids: values.allowed_for_kids || false,
    };

    if (typeof values.allowed_os == "object") {
      const allowedOS = values.allowed_os.map((os) => {
        if (os.value) {
          return os.value;
        } else {
          return os;
        }
      });
      body.allowed_os = allowedOS.join(",");
    } else {
      console.log(values.allowed_os);

      body.allowed_os = values.allowed_os;
    }

    if (typeof values.allowed_countries == "object") {
      const allowedCountries = values.allowed_countries.map((country) => {
        if (country.code) {
          return country.code;
        } else {
          return country;
        }
      });
      body.allowed_countries = allowedCountries.join(",");
    } else {
      body.allowed_countries = values.allowed_countries;
    }

    if (typeof values.allowed_vod_genres == "object") {
      const allowedVodGenres = values.allowed_vod_genres.map((genre) => {
        if (genre.value) {
          return genre.value;
        } else {
          return genre;
        }
      });
      body.allowed_vod_genres = allowedVodGenres?.join(",");
    } else {
      body.allowed_vod_genres = values.allowed_vod_genres;
    }

    if (typeof values.allowed_livetv_genres == "object") {
      const allowedLiveTvGenres = values.allowed_livetv_genres.map((genre) => {
        if (genre.value) {
          return genre.value;
        } else {
          return genre;
        }
      });
      body.allowed_livetv_genres = allowedLiveTvGenres.join(",");
    } else {
      body.allowed_livetv_genres = values.allowed_livetv_genres;
    }

    if (typeof values.custom_allowed_vods == "object") {
      const customAllowedVods = values.custom_allowed_vods.map((vod) => {
        if (vod.value) {
          return vod.value;
        } else {
          return vod;
        }
      });
      body.custom_allowed_vods = customAllowedVods.join(",");
    } else {
      body.custom_allowed_vods = values.custom_allowed_vods;
    }

    if (typeof values.custom_allowed_live_tv_channels == "object") {
      const customAllowedLiveTvChannels = values.custom_allowed_live_tv_channels.map(
        (channel) => {
          if (channel.value) {
            return channel.value;
          } else {
            return channel;
          }
        }
      );
      body.custom_allowed_live_tv_channels = customAllowedLiveTvChannels.join(",");
    } else {
      body.custom_allowed_live_tv_channels = values.custom_allowed_live_tv_channels;
    }

    onSave(body);
  };

  const setEditableData = () => {
    if (editable) {
      const allowedCountry = countriesOptions.filter((country) => {
        return editable.allowed_countries.split(",").includes(country.code);
      });

      const allowedOS = allowedOSOptions.filter((os) => {
        return editable.allowed_os.split(",").includes(os.value);
      });

      const customAllowedVodGenres = vodGenresOptions.filter((vodGenres) => {
        return editable.allowed_vod_genres.includes(vodGenres.id);
      });

      const customAllowedLiveTvGenres = liveTvCategoriesOptions.filter((tvChannels) => {
        return editable.allowed_livetv_genres.includes(tvChannels.id);
      });

      const customAllowedVod = vodOPtions.filter((vod) => {
        return editable.custom_allowed_vods.includes(vod.value);
      });

      const customAllowedLiveTv = liveTvOptions.filter((channels) => {
        return (
          editable.custom_allowed_live_tv_channels
            // .split(",")
            .includes(channels.value)
        );
      });

      form.setFieldsValue({
        name: editable.name,
        allowed_for_kids: editable.allowed_for_kids,
        allowed_countries: allowedCountry,
        allowed_os: allowedOS,
        allowed_vod_genres: customAllowedVodGenres,
        allowed_livetv_genres: customAllowedLiveTvGenres,
        custom_allowed_vods: customAllowedVod,
        custom_allowed_live_tv_channels: customAllowedLiveTv,
      });
    }
  };

  useEffect(() => {
    setEditableData();
  }, [editable]);

  useEffect(() => {
    setAllVodOptions([{ label: "Select All", value: "all" }, ...vodOPtions]);
    setAllLiveTvOptions([{ label: "Select All", value: "all" }, ...liveTvOptions]);
  }, [vodOPtions, liveTvOptions]);

  return (
    <Drawer
      width={600}
      title={editable ? t("edit_filter") : t("add_filter")}
      open={open}
      destroyOnClose
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            gap: 5,
          }}
        >
          <ButtonComponent
            title={t("cancel")}
            type="default"
            onClick={onClose}
            style={{ marginRight: 8 }}
          />
          <ButtonComponent
            title={t("save")}
            type="primary"
            onClick={() => form.submit()}
            isLoading={loading}
          />
        </div>
      }
    >
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item
          label={t("name")}
          name="name"
          rules={[{ required: true, message: t("name_message") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("allowed_countries")}
          name="allowed_countries"
          rules={[{ required: true, message: t("allowed_countries_message") }]}
        >
          <Select mode="multiple" options={countriesOptions} />
        </Form.Item>

        <Form.Item
          label={t("allowed_os")}
          name="allowed_os"
          rules={[{ required: true, message: t("allowed_os_message") }]}
        >
          <Select
            mode="multiple"
            options={[{ label: "Select All", value: "all" }, ...allowedOSOptions]}
            onChange={(values) =>
              handleSelectChange(values, allowedOSOptions, "allowed_os")
            }
          />
        </Form.Item>

        <Form.Item
          label={t("allowed_vod_genres")}
          name="allowed_vod_genres"
          rules={[{ required: true, message: t("allowed_vod_genres_message") }]}
        >
          <Select
            mode="multiple"
            options={vodGenresOptions.map(({ id, name }) => ({ label: name, value: id }))}
          />
        </Form.Item>

        <Form.Item
          label={t("allowed_livetv_genres")}
          name="allowed_livetv_genres"
          rules={[{ required: true, message: t("allowed_livetv_genres_message") }]}
        >
          <Select
            mode="multiple"
            options={liveTvCategoriesOptions.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
          />
        </Form.Item>

        <Form.Item
          label={t("allowed_for_kids")}
          name="allowed_for_kids"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label={t("custom_allowed_vods")}
          name="custom_allowed_vods"
          rules={[{ required: true, message: t("custom_allowed_vods_message") }]}
        >
          <Select
            mode="multiple"
            options={allVodOptions}
            onChange={(values) =>
              handleSelectChange(values, vodOPtions, "custom_allowed_vods")
            }
          />
        </Form.Item>

        <Form.Item
          label={t("custom_allowed_live_tv_channels")}
          name="custom_allowed_live_tv_channels"
          rules={[
            { required: true, message: "Please select custom allowed live TV channels" },
          ]}
        >
          <Select
            mode="multiple"
            options={allLiveTvOptions}
            onChange={(values) =>
              handleSelectChange(
                values,
                liveTvOptions,
                t("custom_allowed_live_tv_channels_message")
              )
            }
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default FilterDrawer;
