import { useEffect, useState } from "react";
import { Form, Input, Drawer, Button, Select } from "antd";
import { useTranslation } from "react-i18next";
import ImageUpload from "../../../../../components/ImageUpload";
import REQUESTS from "../../../../../api/requests";
import { useMusicCategoriesOptions } from "../../../../../hooks/selectOptions";

const MusicDrawer = ({ open, onClose, appHost, editData, onSave, loading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const categoriesOptions = useMusicCategoriesOptions();
  const [image, setImage] = useState({
    url: null,
    file: null,
    status: "uploaded",
  });

  const finish = (values) => {
    const imageUrl = image.url.split(appHost).join("");

    const body = {
      ...values,
      name: values.name,
      category_id: values.category_id.toString(),
    };

    const editImage = editData?.image.split(appHost).join("");
    if (editImage == imageUrl) {
      delete body.image;
    } else {
      body.image = imageUrl;
    }

    onSave(body);
  };

  const onUpload = (value) => {
    try {
      if (value.file === null) return;

      const formData = new FormData();

      formData.append("file", value.file);

      REQUESTS.ENTERTAINMENTS.FILE(formData)
        .then((response) => {
          setImage({
            url: `${appHost}${response.path}`,
            status: "uploaded",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (image.file) {
      onUpload(image);
    }
  }, [image]);

  useEffect(() => {
    if (editData) {
      setImage({
        url: editData?.image,
        status: "uploaded",
      });

      const musicCategoriesId = categoriesOptions.find(
        (item) => item.id == editData?.musics_categories.map((item) => item?.category_id)
      );

      const editableData = {
        ...editData,
        category_id: musicCategoriesId?.value,
      };

      form.setFieldsValue(editableData);
    } else {
      setImage({
        url: null,
        file: null,
        status: "uploaded",
      });
      form.resetFields();
    }
  }, [editData]);

  return (
    <Drawer
      open={open}
      title={t("add_music")}
      onClose={onClose}
      width={600}
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            {t("cancel")}
          </Button>
          <Button onClick={() => form.submit()} type="primary" loading={loading}>
            {t("save")}
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical" onFinish={finish}>
        <Form.Item label={t("image")} name="image">
          <ImageUpload image={image} setImage={setImage} />
        </Form.Item>
        <Form.Item
          label={t("name")}
          name="name"
          rules={[{ required: true, message: t("name_message") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("description")}
          name="description"
          rules={[{ required: true, message: t("description_message") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("url")}
          name="url"
          rules={[
            {
              type: "url",
              message: t("invalid_url"),
            },
            { required: true, message: t("url_message") },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("duration")}
          name="duration"
          rules={[{ required: true, message: t("duration_message") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("singer")}
          name="singer"
          rules={[{ required: true, message: t("singer") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("category_id")}
          name="category_id"
          rules={[{ required: true, message: t("category_id") }]}
        >
          <Select options={categoriesOptions} />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default MusicDrawer;
