import styles from "./styles.module.scss";
import defaultImage from "../../../../../../img/photo.png";
import { useTranslation } from "react-i18next";

const CollectionPreview = ({ collection }) => {
  const { t } = useTranslation();

  console.log(collection);

  return (
    <div
      className={styles["ads-card"]}
      style={{
        backgroundImage: `url(${collection.previewBg})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className={styles["ads-card-logo"]}
        style={{
          backgroundImage: `url(${collection.previewLogo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <h2>{collection.title}</h2>
      <p>{collection.description}</p>
      {collection.address &&
        collection.email &&
        (collection.phone ? (
          <div
            style={{
              width: "max-content",
              marginLeft: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              padding: "10px",
              borderRadius: "10px",
              color: "#fefefe",
              backgroundColor: "rgba(0, 0, 0, 0.38)",
            }}
          >
            <span>{`${t("address")}: ${collection.address}`}</span>

            <span>{`${t("phone")}: ${collection.phone}`}</span>
            <span>{`${t("email")}: ${collection.email}`}</span>
          </div>
        ) : null)}
    </div>
  );
};

export default CollectionPreview;
