import { useEffect, useState } from "react";
import { Button, Drawer, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import ImageUpload from "../../../../../components/ImageUpload";
import REQUESTS from "../../../../../api/requests";

const MusicCategoriesDrawer = ({ open, onClose, onSave, host, editData }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [image, setImage] = useState({
    url: null,
    file: null,
    status: "uploaded",
  });

  const finish = (values) => {
    const imageUrl = image.url.split(host).join("");

    const body = {
      name: values.name,
    };

    const editImage = editData?.image.split(host).join("");

    if (editImage == imageUrl) {
      delete body.image;
    } else {
      body.image = imageUrl;
    }

    onSave(body);
  };

  const onUpload = (value) => {
    try {
      if (value.file === null) return;

      const formData = new FormData();

      formData.append("file", value.file);

      REQUESTS.ENTERTAINMENTS.FILE(formData)
        .then((response) => {
          setImage({
            url: `${host}${response.path}`,
            status: "uploaded",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (image.file) {
      onUpload(image);
    }
  }, [image]);

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        name: editData.name,
      });
      setImage({
        url: host + editData.image,
        status: "uploaded",
      });
    }

    return () => {
      form.resetFields();
      setImage({
        url: null,
        file: null,
        status: "uploaded",
      });
    };
  }, [editData, open]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      width={500}
      title={editData ? t("edit_category") : t("add_category")}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            {t("cancel")}
          </Button>
          <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
            {t("save")}
          </Button>
        </div>
      }
    >
      <Form form={form} onFinish={finish} layout="vertical">
        <Form.Item label={t("image")} name="image">
          <ImageUpload image={image} setImage={setImage} />
        </Form.Item>
        <Form.Item
          label={t("name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("name_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default MusicCategoriesDrawer;
