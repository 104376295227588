import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

const SplashScreenPreview = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div
      className={styles["splash-screen-preview"]}
      style={{
        backgroundImage: `url(${data.previewBg})`,
      }}
    >
      <div className={styles["splash-screen-preview_content"]}>
        <div
          className={styles["splash-screen-preview_logo"]}
          style={{
            backgroundImage: `url(${data.previewLogo})`,
          }}
        />
        <div className={styles["splash-screen-preview_content-wrapper"]}>
          <div className={styles["splash-screen-preview_title"]}>{data.title}</div>
          <div className={styles["splash-screen-preview_description"]}>
            {data.description}
          </div>
        </div>
        {data?.address &&
          data?.email &&
          (data?.phone ? (
            <div className={styles["splash-screen-contact"]}>
              <span>{`${t("address")}: ${data?.address}`}</span>

              <span>{`${t("phone")}: ${data?.phone}`}</span>
              <span>{`${t("email")}: ${data?.email}`}</span>
            </div>
          ) : null)}
      </div>
    </div>
  );
};

export default SplashScreenPreview;
