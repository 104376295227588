import { Flex } from "antd";
import ImpressionChart from "./chart/ImpressionChart";
import EarningByTypeChart from "./chart/EarningByTypeChart";
import EarningByPlatformChart from "./chart/EarningByPlatformChart";
import EarningByRegionChart from "./chart/EarningByRegionChart";
import EarningByTopCLientsChart from "./chart/EarningByTopCLientsChart";
import EarningByTopRegionChart from "./chart/EarningByTopRegionChart";
import REQUESTS from "../../../../api/requests";
import { useEffect, useState } from "react";
import icons from "../../../../config/icons";
import Total from "../vod/totals/Total";

const AdsTab = () => {
  const [data, setData] = useState([]);

  const iconsObj = {
    total_ads_retrieve: icons.LOGS,
    total_campaigns: icons.CUSTOM_ADS,
    total_clicks: icons.COLLECTION,
    total_clients: icons.USERS,
    total_earnings: icons.CUSTOM_ADS,
    total_impressions: icons.LOGS,
    total_running_ads: icons.LOGS,
  };

  const getTotalCount = () => {
    try {
      REQUESTS.DASHBOARD.ADS.GET_TOTAL_COUNT()
        .then((res) => {
          console.log(res);

          const totalData = Object.keys(res).map((key) => {
            return {
              title: key?.split("_").join(" "),
              count: res[key],
              icons: iconsObj[key],
            };
          });

          const filterData = totalData.filter(
            (item) =>
              item.title !== "createdAt" &&
              item.title !== "updatedAt" &&
              item.title !== "id"
          );

          console.log(filterData);

          setData(filterData);

          // Object
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getTotalCount();
  }, []);

  return (
    <>
      <Flex wrap="wrap" gap="10px">
        {data.map((item, index) => {
          return (
            <div
              style={{
                // width: "50%",
                width: "calc(250px - 20px)",
                flex: "1 1 0",
                padding: "0 10px",
              }}
            >
              <Total
                key={index}
                title={item.title}
                count={item.count}
                icon={item.icons}
              />
            </div>
          );
        })}
      </Flex>

      <Flex wrap="wrap" gap="20px">
        <ImpressionChart />
        <EarningByTypeChart />
        <EarningByRegionChart />

        <EarningByPlatformChart />
        <EarningByTopCLientsChart />
        <EarningByTopRegionChart />
        {/* <EarningByTopCampaignChart /> */}
      </Flex>
    </>
  );
};

export default AdsTab;
