import { useEffect, useState } from "react";
import io from "socket.io-client";

import styles from "./_server.module.scss";
import UsersDrawer from "./UsersDrawer";
import GpuInfo from "./GpuInfo";

export default function Info({ data, url, type, t }) {
  const [users, setUsers] = useState({ rows: [], count: 0 });

  const [isOpenUsersDrawer, setIsOpenUsersDrawer] = useState(false);

  const [gpu, setGpu] = useState(null);

  useEffect(() => {
    if (data && data.gpu) {
      // setGpu(data?.gpu.nvidia_smi_log?.gpu);
      setGpu(data?.gpu);
      // let list = [];

      // for (let i = 0; i < 4; i++) {
      //     list.push(data.gpu.nvidia_smi_log?.gpu);
      // }

      // setGpu(list);
    }
  }, [data]);

  // useEffect(() => {
  //     const socket = io(url);
  //     socket.on("connectionList", (data) => {
  //         setUsers(data.message);
  //     });

  //     return () => socket.disconnect();
  // }, []);

  return (
    <>
      {type === "gpu" && <div className={styles["gpu-name"]}>{gpu?.product_name}</div>}
      <div className={styles["server-info"]}>
        <div className={styles["ram-cpu"]}>
          <span>{data?.ram ? data?.ram?.toFixed(0) : 0}%</span>
          <p>{t("ram")}</p>
        </div>

        {type === "gpu" ? (
          <GpuInfo gpu={gpu} />
        ) : (
          <div className={styles["users"]} onClick={() => setIsOpenUsersDrawer(true)}>
            <p>{users.count}</p>

            <div
              style={{ fontSize: 38 }}
              dangerouslySetInnerHTML={{
                __html: '<i class="fas fa-users info-text"></i>',
              }}
            ></div>
          </div>
        )}

        <div className={styles["ram-cpu"]}>
          <span>{data?.cpu ? data?.cpu?.toFixed(0) : 0}%</span>
          <p>{t("cpu")}</p>
        </div>
      </div>

      {/* <UsersDrawer open={isOpenUsersDrawer} onClose={() => setIsOpenUsersDrawer(false)} /> */}
    </>
  );
}
