import { useState } from "react";
import { useTranslation } from "react-i18next";
import PageComponent from "../../../_components/page/PageComponent";
import REQUESTS from "../../../api/requests";
import ResellerTable from "./Resellertable";
import { notification } from "antd";
import ResellersDrawer from "./drawer/ResellersDrawer";
import { useCountriesOptions } from "../../../hooks/selectOptions";
import confirm from "antd/es/modal/confirm";

const ResellersPage = () => {
  const { t } = useTranslation();
  const countryOptions = useCountriesOptions("text");

  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(null);
  const [getAgain, setGetAgain] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const getDataSource = (query, success, error) => {
    setLoading(true);
    try {
      REQUESTS.RESELLERS.RESELLERS.GETTER({ query: JSON.stringify(query) })
        .then((res) => {
          setLoading(false);
          success(res.data);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      console.log(e);
      throw new Error(error);
    }
  };
  const onDelete = (data, type) => {
    setLoading(true);
    try {
      let id = [];

      if (type == "many") {
        id = data.join(",");
      } else {
        id = data?.toString();
      }

      REQUESTS.RESELLERS.RESELLERS.DELETE(id)
        .then((response) => {
          setLoading(false);
          notification.success({
            message: t("success"),
            description: t("deleted_successfully"),
          });
          setGetAgain((prev) => !prev);
        })
        .catch((e) => {
          setLoading(false);
          notification.error({
            message: t("error"),
            description: e.response.data.message,
          });
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onAdd = (values) => {
    try {
      REQUESTS.RESELLERS.RESELLERS.ADD(values)
        .then((response) => {
          notification.success({
            message: t("success"),
            description: t("added_successfully"),
          });
          setShowDrawer(false);
          setGetAgain((prev) => !prev);
          setEditable(null);
        })
        .catch((e) => {
          notification.error({
            message: t("error"),
            description: e.response.data.message,
          });
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onEdit = (values) => {
    try {
      REQUESTS.RESELLERS.RESELLERS.UPDATE(values, editable.id)
        .then((response) => {
          console.log("response", response);

          notification.success({
            message: t("success"),
            description: t("updated_successfully"),
          });
          setShowDrawer(false);
          setEditable(null);
          setGetAgain((prev) => !prev);
        })
        .catch((e) => {
          notification.error({
            message: t("error"),
            description: e.response.data.message,
          });
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSave = (values) => {
    console.log("values", values);
    if (editable) {
      onEdit(values);
    } else {
      onAdd(values);
    }
  };

  const handleClick = ({ key }, record) => {
    switch (key) {
      case "edit":
        setEditable(record);
        setShowDrawer(true);
        break;
      case "delete":
        onDelete(record?.id, "one");
        break;
      default:
        break;
    }
  };

  const handleBulkAction = (key, data, response) => {
    switch (key) {
      case "delete":
        confirm({
          title: t("delete"),
          content: t("delete_user"),
          cancelText: t("cancel"),
          closable: true,
          type: "danger",
          onOk() {
            onDelete(data, "many");
            response(getAgain);
          },
          onCancel() {
            console.log("Cancel");
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <PageComponent routes={[t("resellers"), t("resellers")]}>
      <ResellerTable
        dataSource={getDataSource}
        countryOptions={countryOptions}
        loading={loading}
        handleMenuClick={handleClick}
        getAgain={getAgain}
        addUser={setShowDrawer}
        bulkAction={handleBulkAction}
      />
      <ResellersDrawer
        open={showDrawer}
        editable={editable}
        countryOptions={countryOptions}
        onSave={handleSave}
        onClose={() => {
          setShowDrawer(false);
          setEditable(null);
        }}
      />
    </PageComponent>
  );
};

export default ResellersPage;
