import { useEffect, useState } from "react";
import { Flex } from "antd";
import EpgMetric from "./charts/EpgMetric";
import REQUESTS from "../../../../api/requests";
import { sortData } from "./utils";
import EpgTable from "./table/EpgTable";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../../../../_components/button/ButtonComponent";
import icons from "../../../../config/icons";

const EpgTabs = () => {
  const { t } = useTranslation();

  const [metricData, setMetricData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [metricDae, setMetricDate] = useState("");
  const [title, setTitle] = useState([]);

  const getEpgMetrics = () => {
    try {
      REQUESTS.DASHBOARD.CHARTS.EPG.GET_EPG_METRICS({})
        .then((response) => {
          const metricArr = [];
          const tableArr = [];
          const titleArr = [];

          if (response) {
            parseStatisticDate(response?.last_generated_date);

            sortData(response, (item) => {
              if (item.render_type == "chart") {
                metricArr.push(item.data);
                titleArr.push(item.name);

                setTitle(titleArr);
                setMetricData(metricArr);
              } else {
                tableArr.push({
                  data: item.data,
                  title: item.name,
                });
                setTableData(tableArr);
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const parseStatisticDate = (data) => {
    if (typeof data.data !== "object") {
      const date = new Date(data).toLocaleString().split(",")[0];

      setMetricDate(date);
    }
  };

  const handleDownLoad = () => {
    const element = document.createElement("a");

    element.setAttribute("href", REQUESTS.DASHBOARD.CHARTS.EPG.DOWNLOAD_REPORTS());

    element.style.display = "none";

    element.setAttribute("download", "report.zip");

    element.setAttribute("target", "_blank");

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  useEffect(() => {
    getEpgMetrics();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          padding: "10px",
          marginTop: "20px",
          borderRadius: "5px",
          border: "1px solid #e6e6e6",
        }}
      >
        <h4
          style={{
            margin: 0,
            padding: 0,
            fontSize: "16px",
            fontWeight: "bold",
            color: "#000",
          }}
        >
          {`${t("last_updated_data")} : ${metricDae}`}
        </h4>
        <ButtonComponent
          title={t("download")}
          icon={icons.ARROW_DOWN}
          onClick={handleDownLoad}
        />
      </div>
      <Flex wrap="wrap" gap="20px">
        {metricData?.map((item, idx) => {
          return <EpgMetric data={item.data} key={item.name} title={title[idx]} />;
        })}
      </Flex>
      <br />

      <Flex wrap="wrap" gap="20px">
        {tableData?.map((item) => {
          return (
            <div
              style={{
                width: "calc(50% - 20px)",
                minWidth: "430px",
              }}
            >
              <EpgTable data={item.data} title={item?.title} />
              <br />
            </div>
          );
        })}
      </Flex>
    </div>
  );
};

export default EpgTabs;
