import { useEffect, useState, useCallback } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import PageComponent from "../../../_components/page/PageComponent";
import { notification, Switch } from "antd";
import verticalListImage from "./assets/tv_wertical.svg";
import horizontalListImage from "./assets/tv_horizontal.svg";
import REQUESTS from "../../../api/requests";

const PosterOrientationsPage = () => {
  const { t } = useTranslation();
  const [listType, setListType] = useState("vertical");

  const fetchOrientation = useCallback(async () => {
    try {
      const response = await REQUESTS.APPLICATION.POSTER_ORIENTATIONS.GET();
      if (response?.message) {
        setListType(response.message.isPortrait ? "vertical" : "horizontal");
      } else {
        console.error("Failed to fetch orientation:", response?.error);
      }
    } catch (error) {
      console.error("Error fetching orientation:", error);
    }
  }, []);

  const updateOrientation = async (isHorizontal) => {
    try {
      const response = await REQUESTS.APPLICATION.POSTER_ORIENTATIONS.UPDATE({
        isPortrait: !isHorizontal,
      });
      if (response) {
        notification.success({
          message: t("success"),
          description: t("orientation_updated_message"),
        });
      }
    } catch (error) {
      console.error("Error updating orientation:", error);
      notification.error({
        message: t("error"),
        description: t("failed_to_update_orientation"),
      });
    }
  };

  const handleSwitchChange = (checked) => {
    const newOrientation = checked ? "horizontal" : "vertical";
    setListType(newOrientation);
    updateOrientation(checked);
  };

  useEffect(() => {
    fetchOrientation();
  }, [fetchOrientation]);

  return (
    <PageComponent routes={[t("application"), t("poster_orientations")]}>
      <div className={styles["poster-switch"]}>
        <Switch
          className={styles["switch"]}
          onChange={handleSwitchChange}
          checked={listType === "horizontal"}
          checkedChildren={t("horizontal")}
          unCheckedChildren={t("vertical")}
        />
      </div>
      <div className={styles["poster-wrapper"]}>
        <h6>{t("list_preview")}</h6>
        <div className={styles["poster-preview"]}>
          <img
            src={listType === "vertical" ? verticalListImage : horizontalListImage}
            alt="poster preview"
            className={styles["poster-image"]}
          />
        </div>
      </div>
    </PageComponent>
  );
};

export default PosterOrientationsPage;
