import { useEffect, useState } from "react";
import { Drawer, Form, Input, notification, Select, Space, Checkbox } from "antd";
import REQUESTS from "../../../api/requests";
import { useStreamerServersOptions } from "../../../hooks/selectOptions";
import Resolutions from "./Resolutions";
import ButtonComponent from "../../../_components/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function StreamerStreamsDrawer({ onClose, open, editable, getData }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const serverOptions = useStreamerServersOptions();

  const [selectedResolutions, setSelectedResolutions] = useState([]);
  const [isMaxResolutions, setIsMaxResolutions] = useState(false);
  const [editableResolution, setEditableResolution] = useState(false);

  const [form] = Form.useForm();

  const playerTypeOptions = [
    { label: "HLS", value: "hls" },
    { label: "DASH", value: "dash" },
    { label: "HLS/DASH", value: "hls/dash" },
  ];

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      name: values.name,
      serverId: values.serverId,
      encode_audio: values.encode_audio,
      resolutions: selectedResolutions,
      loop: false,
      start_max_resolutions: isMaxResolutions,
      type: values.type,
    };

    const request = editable
      ? REQUESTS.STREAMER.STREAMS.EDIT({ ...body, id: editable.id })
      : REQUESTS.STREAMER.STREAMS.ADD(body);

    request
      .then((response) => {
        setIsLoading(false);
        if (response.error) {
          notification.error({ description: response.message });
          return;
        }
        getData();
        onClose();
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setSelectedResolutions([]);
      setIsMaxResolutions(false);
    }

    if (editable) {
      form.setFieldsValue({
        name: editable.name,
        encode_audio: editable.encode_audio,
        serverId: editable.serverId,
        type: editable.type,
      });

      setEditableResolution(editable.start_max_resolutions);
      const editableResolutions = editable.resolutions.map((item) => ({
        id: item.id,
        input: item.info.input,
        name: item.name,
        output: item.info.output,
      }));
      setSelectedResolutions(editableResolutions);
    }
  }, [open, editable]);

  return (
    <Drawer
      width={600}
      title={`${editable ? t("edit") : t("add")} ${t("stream")}`}
      placement="right"
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      open={open}
      destroyOnClose
    >
      <Form
        form={form}
        name="streamer-streams"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          serverId: serverOptions[0]?.value,
          encode_audio: false,
        }}
      >
        <Form.Item
          label={t("name")}
          name="name"
          rules={[{ required: true, message: t("name_message") }]}
        >
          <Input />
        </Form.Item>
        <>
          <Space>
            <Form.Item
              name="encode_audio"
              valuePropName="checked"
              style={{ margin: "0 auto" }}
            >
              <Checkbox>{t("encode_audio")}</Checkbox>
            </Form.Item>

            <Form.Item
              label={t("server")}
              name="serverId"
              rules={[{ required: true, message: t("server_message") }]}
            >
              <Select options={serverOptions} style={{ width: 390 }} />
            </Form.Item>
          </Space>
          <Form.Item label={t("player_type")} name="type">
            <Select
              options={playerTypeOptions}
              defaultValue={playerTypeOptions[0].value}
            />
          </Form.Item>
        </>

        {open && (
          <Resolutions
            editable={editable}
            setSelectedResolutions={setSelectedResolutions}
            isChecked={setIsMaxResolutions}
            defaultChecked={editableResolution}
          />
        )}

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
