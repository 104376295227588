import { useCallback } from "react";
import ImageUpload from "../../../../components/ImageUpload";

import { Switch, Form } from "antd";

const WaterMarkerToggle = ({
  showWaterMarker,
  setShowWaterMarker,
  form,
  t,
  waterMarker,
  setWaterMarker,
}) => {
  const handleWaterMarker = useCallback(
    (value) => {
      setShowWaterMarker(value);
      if (!value) {
        form.setFields([{ name: "image_watermarker", value: null }]);
      }
    },
    [form]
  );

  return (
    <>
      <Form.Item label={t("enable_watermarker")} name="enable_watermark">
        <Switch
          checkedChildren={t("on")}
          unCheckedChildren={t("off")}
          onChange={handleWaterMarker}
        />
      </Form.Item>
      {showWaterMarker && (
        <Form.Item label={t("image_watermarker")} name="image_watermarker">
          <ImageUpload image={waterMarker} setImage={setWaterMarker} />
        </Form.Item>
      )}
    </>
  );
};

export default WaterMarkerToggle;
