import Axios from "axios";

const authHost =
  process.env?.REACT_APP_RESELLER_API_HOST || "DEFAULT_REACT_RESELLER_API_HOST";
const USERNAME =
  process.env.REACT_APP_RESELLER_USER_NAME || "DEFAULT_APP_RESELLER_USER_NAME";
const PASSWORD =
  process.env.REACT_APP_RESELLER_PASSWORD || "DEFAULT_APP_RESELLER_PASSWORD";

console.log(authHost);

const authInstance = Axios.create({
  baseURL: authHost,
  method: "POST",
  timeout: 20000,
});

authInstance.interceptors.request.use(
  (config) => config,
  (error) => {
    console.error("Error adding token to headers:", error);
    return Promise.reject(error);
  }
);

const getToken = async () => {
  try {
    const response = await authInstance.post("auth/login", {
      username: USERNAME,
      password: PASSWORD,
    });

    if (response.data && response.data.token) {
      sessionStorage.setItem("authToken", response.data.token);
      return response.data.token;
    }
    throw new Error("Token not found in response");
  } catch (error) {
    console.error("Error fetching token:", error);
    throw error;
  }
};

const apiInstance = Axios.create({
  baseURL: authHost,
  timeout: 20000,
});

apiInstance.interceptors.request.use(
  async (config) => {
    try {
      let token = sessionStorage.getItem("authToken");

      if (!token) {
        token = await getToken();
      }

      config.headers.authorization = `Bearer ${token}`;
    } catch (error) {
      console.error("Error adding token to headers:", error);
      throw error;
    }
    return config;
  },
  (error) => {
    console.error("Error adding token to headers:", error);
    return Promise.reject(error);
  }
);

export default apiInstance;
