import { useState } from "react";
import PageComponent from "../../../_components/page/PageComponent";
import { useTranslation } from "react-i18next";
import ClientTable from "./ClientTable";
import REQUESTS from "../../../api/requests";
import ClientDrawer from "./drawer/ClientDeawer";
import { notification } from "antd";

const ClientPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [getAgain, setGetAgain] = useState(false);
  const [editable, setEditable] = useState(null);

  const getDataSource = (query, success, error) => {
    setLoading(true);
    try {
      REQUESTS.CUSTOM_ADS.CLIENT.GETTER({ query: JSON.stringify(query) })
        .then((response) => {
          setLoading(false);

          if (!response.error) {
            success(response?.data?.rows);
          } else {
            error(response.error);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = (values) => {
    if (editable) {
      try {
        REQUESTS.CUSTOM_ADS.CLIENT.EDIT(values, editable.id)
          .then((response) => {
            if (!response.error) {
              setShowDrawer(false);
              setEditable(null);

              setGetAgain(!getAgain);
              notification.success({
                message: "Success",
                description: "Client updated successfully",
              });
            } else {
              notification.error({
                message: "Error",
                description: response.error,
              });
              console.log("Error:", response.error);
            }
          })
          .catch((error) => {
            console.log("Error:", error);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        REQUESTS.CUSTOM_ADS.CLIENT.ADD(values)
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: "Success",
                description: "Client added successfully",
              });
              setShowDrawer(false);
              setGetAgain(!getAgain);
            } else {
              notification.error({
                message: "Error",
                description: response.error,
              });
              console.log("Error:", response.error);
            }
          })
          .catch((error) => {
            console.log("Error:", error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onDelete = (data, type) => {
    try {
      let id = [];

      if (type == "many") {
        id = data.join(",");
      } else {
        id = data?.toString();
      }

      REQUESTS.CUSTOM_ADS.CLIENT.DELETE(id)
        .then((response) => {
          if (!response.error) {
            console.log("Success:", response);
            notification.success({
              message: "Success",
              description: "Client deleted successfully",
            });
            setGetAgain(!getAgain);
          } else {
            notification.error({
              message: "Error",
              description: response.error,
            });
            console.log("Error:", response.error);
          }
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = ({ key }, data) => {
    switch (key) {
      case "add":
        setShowDrawer(true);
        break;
      case "edit":
        setEditable(data);
        setShowDrawer(true);
        break;
      case "delete":
        onDelete(data.id, "single");
        console.log("delete");

        break;
      default:
        break;
    }
  };

  const handleBulkAction = (key, data, response) => {
    switch (key) {
      case "delete":
        onDelete(data, "many");
        response(getAgain);
        break;
      default:
        break;
    }
  };

  return (
    <PageComponent routes={[t("custom_ads"), t("clients")]}>
      <ClientTable
        getDataSource={getDataSource}
        loading={loading}
        handleMenuClick={handleClick}
        getAgain={getAgain}
        bulkAction={handleBulkAction}
      />
      <ClientDrawer
        open={showDrawer}
        onSave={handleSave}
        editable={editable}
        onClose={() => {
          setShowDrawer(false);
          setEditable(null);
        }}
      />
    </PageComponent>
  );
};

export default ClientPage;
