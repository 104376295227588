import { useState } from "react";
import { useTranslation } from "react-i18next";
import PageComponent from "../../../../_components/page/PageComponent";
import REQUESTS from "../../../../api/requests";
import { notification } from "antd";
import { appHost } from "../../../../helpers/appHost";
import GameCategoriesDrawer from "./drawer/GameCategoriesDrawer";
import GameCategoriesTable from "./GameCategoriesTable";

const GameCategoriesPage = () => {
  const { t } = useTranslation();

  const host = appHost + "entertainment/";

  const [getAgain, setGetAgain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [editData, setEditData] = useState(null);

  const getDataSource = (query, success, error) => {
    setLoading(true);
    try {
      REQUESTS.ENTERTAINMENTS.GAME.CATEGORIES_GETTER({ query: JSON.stringify(query) })
        .then((response) => {
          setLoading(false);
          success(response);
        })
        .catch((err) => {
          setLoading(false);
          error(err);
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onDelete = (data, type) => {
    setLoading(true);
    try {
      let id = [];
      if (type == "many") {
        id = data.join(",");
      } else {
        id = data?.toString();
      }

      REQUESTS.ENTERTAINMENTS.GAME.CATEGORIES_DELETE(id)
        .then((response) => {
          setLoading(false);
          notification.success({
            message: t("success"),
            description: t("deleted_successfully"),
          });
          setGetAgain((prev) => !prev);
        })
        .catch((error) => {
          setLoading(false);
          notification.error({
            message: t("error"),
            description: t("something_wrong"),
          });
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuClick = ({ key }, record) => {
    console.log(key, record);
    switch (key) {
      case "delete":
        onDelete(record.id, "one");
        break;
      case "edit":
        setEditData(record);
        setOpenDrawer(true);
        break;
      default:
        break;
    }
  };

  const handleSave = (data) => {
    if (editData) {
      onEdit(data);
    } else {
      onAdd(data);
    }
  };

  const onAdd = (value) => {
    setLoading(true);
    try {
      REQUESTS.ENTERTAINMENTS.GAME.ADD_CATEGORY(value)
        .then((response) => {
          setLoading(false);
          notification.success({
            message: t("success"),
            description: t("saved_successfully"),
          });
          setOpenDrawer(false);
          setGetAgain((prev) => !prev);
        })
        .catch((error) => {
          setLoading(false);
          notification.error({
            message: t("error"),
            description: t("something_wrong"),
          });
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onEdit = (value) => {
    try {
      REQUESTS.ENTERTAINMENTS.GAME.EDIT_CATEGORY(editData.id, value)
        .then((response) => {
          setLoading(false);
          notification.success({
            message: t("success"),
            description: t("saved_successfully"),
          });
          setOpenDrawer(false);
          setGetAgain((prev) => !prev);
          setEditData(null);
        })
        .catch((error) => {
          setLoading(false);
          notification.error({
            message: t("error"),
            description: t("something_wrong"),
          });
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageComponent routes={[t("game"), t("categories")]}>
      <GameCategoriesTable
        getDataSource={getDataSource}
        getAgain={getAgain}
        loading={loading}
        handleMenuClick={handleMenuClick}
        host={host}
        onAdd={() => {
          setOpenDrawer(true);
        }}
      />
      <GameCategoriesDrawer
        open={openDrawer}
        onSave={handleSave}
        host={host}
        editData={editData}
        onClose={() => {
          setOpenDrawer(false);
          setEditData(null);
        }}
      />
    </PageComponent>
  );
};

export default GameCategoriesPage;
