import { useCallback, useEffect, useState } from "react";
import { Form, Input, Space, Switch, Tabs, notification } from "antd";
import icons from "../../../../config/icons";
import PathDrawer from "./PathDrawer";
import Upploader from "../../../../_components/uploader/Uploader";
import ButtonComponent from "../../../../_components/button/ButtonComponent";
import REQUESTS from "../../../../api/requests";
import { useTranslation } from "react-i18next";
import ImageUpload from "../../../../components/ImageUpload";
import WaterMarkerToggle from "./WaterMarkerToggle";

export default function InputField({ form, setProgress, progress }) {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("select_file");
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [showWaterMarker, setShowWaterMarker] = useState(false);
  const [waterMarker, setWaterMarker] = useState({
    url: null,
    file: null,
    status: "uploaded",
  });

  const handleInput = (value) => {
    form.setFields([{ name: "select_file", value }]);
    setIsOpenDrawer(false);
  };

  useEffect(() => {
    form.setFields([{ name: "input_type", value: activeTab }]);
  }, [activeTab]);

  const uploadFile = async (value, invokeBy) => {
    if (!value) return;

    let file = invokeBy === "image_watermarker" ? value : value.file.originFileObj;

    try {
      const statusResponse = await REQUESTS.VOD.TRANSCODING.UPLOAD.STATUS({
        file_id: file.uid + file.name,
      });

      const totalChunkUploaded = statusResponse?.total_chunk_uploaded || 0;

      const headers = {
        "starting-byte": totalChunkUploaded,
        "file-id": file.uid + file.name,
        "chunk-size": file.size - totalChunkUploaded,
        "file-size": file.size,
        ...(invokeBy === "image_watermarker" && { type: "image" }),
      };

      file = file.slice(totalChunkUploaded, file.size);

      const formData = new FormData();
      formData.append("file", file, file.name);

      const onProgress = (e) => {
        if (invokeBy === "choose_file") setProgress(e);
      };

      const response = await REQUESTS.VOD.TRANSCODING.UPLOAD.START(
        formData,
        headers,
        onProgress
      );

      if (response.message === "Finish") {
        if (invokeBy === "image_watermarker") {
          setWaterMarker({
            url: response.file_path,
            file: null,
            status: "uploaded",
          });
          form.setFields([{ name: "image_watermarker", value: response.file_path }]);
        } else if (invokeBy === "choose_file") {
          form.setFields([
            { name: "choose_file", value: response.file_path },
            { name: "image_watermarker", value: waterMarker.url },
          ]);
          setTimeout(() => setProgress(0), 1000);
        }
      } else {
        throw new Error(t("something_wrong"));
      }
    } catch (error) {
      notification.error({
        message: t("error"),
        description: error?.message || t("something_wrong"),
      });
    }
  };

  useEffect(() => {
    if (waterMarker?.file) {
      uploadFile(waterMarker?.file, "image_watermarker");
    }
  }, [waterMarker]);

  const tabs = [
    {
      label: t("select_file"),
      key: "select_file",
      children: (
        <Space>
          <Form.Item
            label={t("input")}
            name="select_file"
            rules={[
              {
                required: activeTab === "select_file",
                message: t("choose_file_message"),
              },
            ]}
          >
            <Input style={{ width: 510 }} readOnly />
          </Form.Item>
          <ButtonComponent
            style={{ marginTop: 5 }}
            type="dashed"
            icon={icons.FOLDER}
            onClick={() => setIsOpenDrawer(true)}
          />
        </Space>
      ),
    },
    {
      label: t("choose_file"),
      key: "choose_file",
      children: (
        <>
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}></div>
          <Form.Item
            label={t("input")}
            name="choose_file"
            rules={[
              {
                required: activeTab === "choose_file",
                message: t("choose_file_message"),
              },
            ]}
          >
            <Upploader
              disabled={progress > 0}
              style={{ width: 550 }}
              onChange={(value) => uploadFile(value, "choose_file")}
            />
          </Form.Item>
        </>
      ),
    },
    {
      label: "Url",
      key: "url",
      children: (
        <Form.Item
          label={t("input")}
          name="url"
          rules={[{ required: activeTab === "url", message: t("please_input_url") }]}
        >
          <Input />
        </Form.Item>
      ),
    },
  ];

  return (
    <Form.Item name="input_type">
      <WaterMarkerToggle
        showWaterMarker={showWaterMarker}
        setShowWaterMarker={setShowWaterMarker}
        waterMarker={waterMarker}
        setWaterMarker={setWaterMarker}
        form={form}
        t={t}
      />

      <Tabs tabPosition="top" items={tabs} onChange={setActiveTab} />
      <PathDrawer
        type="input"
        onSelect={handleInput}
        open={isOpenDrawer}
        onClose={() => setIsOpenDrawer(false)}
      />
    </Form.Item>
  );
}
