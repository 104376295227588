import { useEffect, useState } from "react";

import REQUESTS from "../../../../api/requests";

import DevicesList from "./devices/DevicesList";

import StatisticsList from "./statistics/StatisticsList";
import PaymentsChart from "./charts/PaymentsChart";
import OnlineUserChart from "./charts/users/OnlineUsercharts";
import { Flex, Row } from "antd";
import RegisteredUsersCart from "./charts/users/RegisteredUsersCart";
import CountriesChart from "./charts/CountriesChart";
import PlatformChart from "./charts/PlatformChart";
import PaymentCountChart from "./charts/PaymentCountChart";
import GroupUserChart from "./charts/users/GroupUserChart";

export default function GeneralTab({ permissions }) {
  const [liveTvStatistic, setLiveTvStatistic] = useState(null);

  const [usersStatistic, setUsersStatistic] = useState(null);

  const [paymentPermissions, setPaymentPermissions] = useState(false);

  const getLiveTvStatistic = () => {
    REQUESTS.DASHBOARD.LIVE_TV().then((response) => {
      if (!response.error) {
        setLiveTvStatistic(response?.message);
      }
    });
  };

  const getUsersStatistic = () => {
    REQUESTS.DASHBOARD.USERS().then((response) => {
      if (!response.error) {
        setUsersStatistic(response?.message);
      }
    });
  };

  useEffect(() => {
    getLiveTvStatistic();
    getUsersStatistic();
  }, []);

  useEffect(() => {
    try {
      const permissionsObj = Object?.keys(permissions?.payments);
      permissionsObj?.forEach((item, idx) => {
        if (permissions["payments"][item] === true) {
          setPaymentPermissions(true);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }, [permissions]);

  return (
    <div>
      <StatisticsList usersStatistic={usersStatistic} liveTvStatistic={liveTvStatistic} />
      <CountriesChart style={{ width: 600 }} />
      <DevicesList />
      <Flex wrap="wrap" gap="20px">
        {paymentPermissions && (
          <>
            <PaymentsChart />

            <PaymentCountChart />
          </>
        )}
        <OnlineUserChart />
        <RegisteredUsersCart />
        <PlatformChart />
        <GroupUserChart />
      </Flex>
    </div>
  );
}
